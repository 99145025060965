import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import icon from '../../svg/fitnesstimer_logo.svg';
import Button from '../Button';

const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div className="w-12 mr-3">
          <img src={icon} />
        </div>
        FitnessTimer Coach
      </div>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4" href="#features">
          Features
        </AnchorLink>
        <AnchorLink className="px-4" href="#aboutapp">
          About App
        </AnchorLink>
        <AnchorLink className="px-4" href="#technicalsupport">
          Technical Support
        </AnchorLink>
        <AnchorLink className="px-4" href="#privacydetails">
          Privacy details
        </AnchorLink>
        <AnchorLink className="px-4" href="#download">
          Download
        </AnchorLink>
      </div>
    </div>
  </header>
);

export default Header;
