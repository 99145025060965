import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import bigScreen from '../svg/bigScrenn.png';
import newTraining from '../svg/newTraining.png';
import trainingPlayer from '../svg/trainingPlayer.png';
import trainingReview from '../svg/trainingReview.png';
import darkmode from '../svg/darkmode.png';

export default () => (
  <Layout>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            FitnessTimer Coach
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Application for home training when the coach is missing.
          </p>
          <p className="mt-4 text-gray-600">Train in the comfort of your home.</p>
        </div>
        <div className="lg:w-1/2">
          <img src={bigScreen} />
        </div>
      </div>
    </section>
    <section id="features" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">About FitnessTimer Coach</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Advantage One</p>
              <p className="mt-4">
                The first application where you compile your training exactly to measure.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Advantage Two</p>
              <p className="mt-4">
                As a trainer you can create, save and send your training to your clients or friends.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Advantage Three</p>
              <p className="mt-4">
                Darkmode is enabled and the application can read the exercises itself.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <SplitSection
      id="aboutapp"
      background="bg-blueGray-100"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Manage your exercises</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Create your workout routine by adding exercises one by one. You can choose if the
            exercise is count or time-based, its equipment, intensity, tempo, repetitions, rests
            between, and rest after the whole set.
          </p>
        </div>
      }
      secondarySlot={<img src={newTraining} />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">Training Review</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            You can review the whole routine at once, clone exercises separately, add more rests,
            move the exercises ad rest, or delete them. You can also save all routines on your Mac
            or iDrive, or print them on your printer.
          </p>
        </div>
      }
      secondarySlot={<img src={trainingReview} />}
    />
    <SplitSection
      background="bg-blueGray-100"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Player with loud reading</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            When you are ready to workout already, you can play your routine in our player. The
            player has a built-in voice loud reading for the current and next exercise, and if the
            workout is time-based, the countdown will start.
          </p>
        </div>
      }
      secondarySlot={<img src={trainingPlayer} />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">Dark Mode</h3>
          <p className="mt-8 font-light leading-relaxed">
            The FitnessTimer Coach has built-in support for dark mode.
          </p>
        </div>
      }
      secondarySlot={<img src={darkmode} />}
    />

    <section id="technicalsupport" className="py-20 lg:py-40 bg-blueGray-100">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">Technical Support</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          <div className="flex-1 px-3">
            <p className="mt-8 text-xl font-light leading-relaxed">
              Please report any problems with the application via the email address&nbsp;
              <a
                href="mailto:fitnesstimer@mikesovi.com"
                className="underline hover:text-primaryIcon"
              >
                fitnesstimer@mikesovi.com
              </a>
              . After sending the email, you will be informed by the bot about the registration of
              your request and further communication will take place through our support system.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="privacydetails" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">Privacy details</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          <div className="flex-1 px-3">
            <p className="mt-8 text-xl font-light leading-relaxed">
              Your privacy is completely safe with us. The application itself does not collect any
              data and in no case does it send any data outside of your computer. If you specify any
              data in the application yourself, it is only stored in a file that is fully under your
              control.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section
      id="download"
      className="container mx-auto my-20 py-24 bg-blueGray-200 rounded-lg text-center"
    >
      <h3 class="text-5xl font-semibold">Ready to start?</h3>
      <p class="mt-8 text-xl font-light">
        Download our app for free and start training in comfort of your home today.
      </p>
      <p class="mt-8">
        <a
          href="https://apps.apple.com/us/app/fitnesstimer-coach/id1548187263"
          class="py-5 px-16 text-lg bg-primaryIcon hover:bg-primaryHover rounded text-white"
        >
          Start Your Training Now
        </a>
      </p>
    </section>
  </Layout>
);
