import React from 'react';
import Helmet from 'react-helmet';
import Header from './Header';

const Layout = ({ children }) => {
  return (
    <>
      <Helmet
        title="FitnessTimer Coach"
        meta={[
          { name: 'description', content: 'FitnessTimer Coach' },
          { name: 'keywords', content: 'fitness, timer, coach, training, home, exercise, workout' }
        ]}
      />
      <Header />
      <main className="text-gray-900">{children}</main>
    </>
  );
};

export default Layout;
